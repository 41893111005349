#about-me {

    h1 {
        color: $accent-color;
    }

    .images {
        .vertical-images {
            .cell:first-of-type {
                margin-bottom: 16px;
            }
        }
    }
}
