#footer {
    background-color: $purple;
    border-top: 4px solid darken($purple, 10);
    color: white;

    ul {
        list-style: none;
        li {
            list-style-type: none;
            display: inline-block;

            &:not(:last-of-type) {
                margin-right: 12px;
                padding-right: 12px;
                border-right: 1px solid white;
                a {
                    color: $accent-color;
                }
            }
        }
    }
}
