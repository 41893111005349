#things-i-love {
    h1 {
        font-style: italic;
        font-size: 2em;
    }

    .icons {
        margin-top: 2em;

        img {
            width: 45px;
            margin-right: calc(#{(100% / 8)} - 45px);

            &:last-child {
                margin-right: 0;
            }

            @include breakpoint(small down) {
                margin-right: 12px;
                margin-bottom: 12px;
                width: 32px; 
            }
        }
    }
}