$opensans: 'Open Sans', sans-serif;
$merriweather: 'Merriweather', serif;

$font-color: #1C1C1C;
$accent-color: #0FB696;
$purple: #2c3e50;

$section-horizontal-padding: 2em;
$section-vertical-padding: 6em;

$section-horizontal-padding-mobile: 0em;
$section-vertical-padding-mobile: 4em;
