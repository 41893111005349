#business-owner {
    
    background-color: #FAFAFA;

    h1 {
        color: $accent-color;
    }

    .tags {
        span {
            color: $accent-color;
        }

        @include breakpoint(small down) {
            span {
                font-size: 0.7em;
            }
        }
    }

    #contact-card {

        position: relative;
        overflow: hidden;

        .circle {
            width: 700px;
            height: 700px;
            border-radius: 50%;
            background-color: transparentize(white, 0.9);
            position: absolute;
            top: -100px;
            left: -400px;
        }

        @include breakpoint(small down) {
            margin-bottom: 4em;
        }

        background-color: $accent-color;
        max-width: 440px;
        min-height: 200px;

        padding-top: 55px;

        span {
            color: white;
            display: block;
        }

        .details {
            padding-left: 20px;

            span:nth-of-type(1) {
                font-family: $merriweather;
            }

            span:nth-of-type(2) {
                font-weight: bold;
            }

            span:nth-of-type(3) {
                font-size: 12px;
            }
        }
    }
}
