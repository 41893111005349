body {
    text-rendering: optimizeLegibility;
    color: $font-color;
    font-family: $opensans;
}

.button {
    background-color: $accent-color;
    border-radius: 4px;

    &.action {
        background-color: $purple;
        border-bottom: 2px solid darken($purple, 10);
    }
}

.section {
    @include section-padding;

    h1 {
        margin-bottom: 0.5em;
    }

    p.paragraph {
        line-height: 30px;
    }
}

.stripe {
    height: 2px;
    width: 50px;
    background-color: $accent-color;
    margin: auto;
}

h1 {
    font-family: $merriweather;

    @include breakpoint(small down) {
        font-size: 2.4em;
    }
}
