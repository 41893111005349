.top-bar {

    background-color: transparent;
    position: fixed;
    width: 100%;
    z-index: 2;
    padding: 0 40px;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid transparent;

    &.dark {
        background-color: $accent-color;
        border-bottom: 2px solid darken($accent-color, 5);
    }

    &.hidden-up {
        @extend .dark;
    }

    .menu {
        background-color: transparent;
        padding: 20px 0;

        a {
            color: white;
            font-weight: 600;

            &:not(.action) {
                border-bottom: 2px solid transparent;
                transition: border-bottom-color 0.3s ease;
            }

            &:hover:not(.action) {
                border-bottom: 2px solid white;
            }
        }

        li {
            margin: 0 50px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.show-for-small-only {
    background-color: $accent-color;
    .title-bar {
        background-color: $accent-color;
    }
}

.off-canvas {
    background-color: $accent-color;

    ul.menu.vertical {
        li a {
            color: white;
            margin: 12px 0;
            font-weight: bold;
        }
    }
}
