#contact {
    background-color: $accent-color;
    color: white;

    form {
        label {
            color: white;
            font-size: 1.2em;
        }

        input, textarea {
            border: none;
            color: white;
            background-color: $accent-color;
            border: 1px solid rgba(255, 255, 255, 0.2);
            outline: none;
            transition: 0.3s ease background-color;

            &:focus {
                outline: none;
                box-shadow: none;
                background-color: darken($accent-color, 2);
            }
        }

        button[type=submit] {
            background-color: $purple;
            border-bottom: 2px solid darken($purple, 20);
            padding: 16px;
            color: white;
            cursor: pointer;
            transition: background-color 0.5s ease-in-out, opacity 0.5s ease-in-out;
            border-radius: 4px;

            &:hover {
                background-color: darken($purple, 10);
            }

            &[disabled=disabled] {
                opacity: 0.5;
            }
        }

        #success, #error {
            vertical-align: middle;
            display: inline-block;
            margin-left: 18px;
            margin-top: 8px;
            font-size: 1.2em;
        }
    }
}
