#software-developer {
    background: url(/assets/images/pattern.png) $accent-color;
    background-size: 44px 44px;
    position: relative;

    h1 {
        color: white;
    }

    .paragraph {
        color: white;
    }

    .tags {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        color: white;
        font-size: small;
    }
}