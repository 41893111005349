#project {
    padding: 4em 0;

    @include breakpoint(small down) {
        padding: 0;
    }

    .heading {
        padding-top: 4em;
        padding-bottom: 2em;

        &.light {
            h1, h2, .date {
                color: $font-color;
            }
        }
    }

    #logo {
        max-height: 278px;
        overflow: hidden;
        margin-bottom: 2em;
        .wrapper {
            display: inline-block;
            max-height: 278px;
        }
    }

    span.label {
        margin: 0 8px;
        background-color: $accent-color;
        font-weight: bold;
        border-radius: 4px;
    }

    h1 {
        color: white;
        font-size: 3em;
    }

    h2 {
        color: white;
        font-size: 1.3em;
        font-weight: 600;
    }

    p.description {
        font-size: 1.4em;
        margin: 6em 0;
        white-space: pre-wrap;
    }

    .date {
        color: transparentize(white, 0.2);
    }

    h3 {
        color: $accent-color;
        font-size: 1.2em;
        font-weight: bold;
        margin: 1em 0;
    }

    ul#responsibilities, ul#features {
        font-weight: bold;
        margin-left: 0;
        li {
            list-style-type: none;
            text-transform: capitalize;
        }
    }

    #info {
        a.btn {
            width: 100%;
            color: white;
            background-color: $accent-color;
            display: inline-block;
            margin: 4px 0;
            padding: 8px 12px;
            border-bottom: 2px solid darken($accent-color, 5);
            font-weight: bold;
            border-radius: 4px;
        }
    }
}
