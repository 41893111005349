#no-problem-software {
    background-color: #FAFAFA;

    h1 {
        line-height: 1em;
    }

    h4 {
        color: #BFBFBF;
        font-family: $merriweather;
        font-style: italic;
        line-height: 1em;
    }

    h1 {
        font-family: $opensans;
    }

    .stripe {
        margin-bottom: 4em;
    }
}

.tilter {
	position: relative;
	display: inline-block;
	flex: none;
	width: 300px;
	height: 415px;
	margin: 1.5em 1.5em;
	color: #fff;
	perspective: 1000px;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.22);
}

.tilter * {
	pointer-events: none;
}

.tilter:hover,
.tilter:focus {
	color: #fff;
	outline: none;
}

.tilter__figure,
.tilter__image {
	display: block;
	width: 100%;
	height: 100%;
	margin: 0;
}

.tilter__figure > * {
	transform: translateZ(0px); /* Force correct stacking order */
}

.tilter__figure {
	position: relative;
}

.tilter__figure::before {
	content: '';
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	box-shadow: 0 30px 20px rgba(35,32,39,0.5);
}

.tilter__deco {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.tilter__deco--overlay {
	background-image: linear-gradient(45deg, rgba(36, 0, 80, 0.2), rgba(0, 89, 71, 0.2), rgba(15, 182, 150, 0.2));
}

.tilter__deco--shine div {
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 100%);
}

.tilter__deco--lines {
	fill: none;
	stroke: transparentize(white, 0.6);
	stroke-width: 3px;
}

.tilter__caption {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 4em;
}

.tilter__title {
	font-family: 'Abril Fatface', serif;
	font-size: 2.5em;
	font-weight: normal;
	line-height: 1;
	margin: 0;
}

.tilter__description {
	font-size: 0.85em;
	margin: 1em 0 0 0;
	letter-spacing: 0.15em;
}
