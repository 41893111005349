#appdownload {
    padding: 24px 0;

    p.step {
        font-weight: bold;
        font-size: inherit;
        color: $font-color;
        margin: 48px 0;
    }

    p {
        font-size: 13px;
        color: darken(white, 55);
        line-height: 1.6em;
    }

    @include breakpoint(small down) {
        .cell {
            margin: 24px 0 0;
            padding: 0 24px;

            h3 {
                font-size: 2em;
                font-weight: bold;
                margin-right: 40px;
                line-height: 1em;
            }

            h4 {
                font-size: 1em;
                margin-top: 12px;
                color: grey;
            }
        }

        p.step {
            margin: 24px 0;
        }
    }

    @include breakpoint(medium up) {
        .grid-x {
            margin-top: 24px;
        }

        h3 {
            font-weight: bold;
            font-size: 1.5em;
        }

        h4 {
            font-size: 1em;
            color: grey;
        }
    }
}
