@mixin title {
    font-family: $merriweather;
}

@mixin section-padding {
    padding: $section-vertical-padding $section-horizontal-padding;

    @include breakpoint(small down) {
        padding: $section-vertical-padding-mobile $section-horizontal-padding-mobile;
    }
}