#intro-wrapper {
    
    background-color: $accent-color;

    #typed {
        display: inline-block;
        position: relative;

        span.highlight {
            color: $accent-color;
            border-bottom: 3px solid $font-color;
        }
    }

    h1 {
        color: white;
        font-size: 80px;
        line-height: 160px;
    }

    p {
        color: white;

        &:nth-child(1) {
            font-size: 20px;
            line-height: 20px;
        }

        &:nth-child(2) {
            font-size: 24px;
            line-height: 24px;
        }

        &:nth-child(3) {
            font-size: 28px;
            line-height: 28px;
        }

        &:nth-child(4) {
            font-size: 32px;
            line-height: 32px;
        }

        &:nth-child(5) {
            font-size: 36px;
            line-height: 36px;
        }

        @include breakpoint(small down) {
            &:nth-child(1) {
                font-size: 1em;
                line-height: 1em;
            }
    
            &:nth-child(2) {
                font-size: 1.1em;
                line-height: 1.1em;
            }
    
            &:nth-child(3) {
                font-size: 1.2em;
                line-height: 1.2em;
            }
    
            &:nth-child(4) {
                font-size: 1.4em;
                line-height: 1.4em;
            }
    
            &:nth-child(5) {
                font-size: 1.5em;
                line-height: 1.5em;
            }
        }
    }

    #continue {
        $size: 20px;
        width: 0; 
        height: 0; 
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid transparentize(white, 0.6);

        position: absolute;
        bottom: 40px;

        cursor: pointer;

        $half-size: ($size / 2);
        left: calc(50% - #{$half-size});

        &:hover {
            border-top-color: white;
            transition: all 1s ease;
        }

        -webkit-animation-duration: 3s;
        -webkit-animation-delay: 2s;
        -webkit-animation-iteration-count: infinite;

        animation-duration: 3s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
    }
}

/* Typed */

.typed-cursor {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
    animation-name: typedjsBlink;
    animation-duration: 0.7s;
    animation-timing-function: initial;
    animation-delay: initial;
    animation-iteration-count: infinite;
    animation-direction: initial;
    animation-fill-mode: initial;
    animation-play-state: initial;
}

 @keyframes typedjsBlink{
    50% { opacity: 0.0; }
}

@-webkit-keyframes typedjsBlink{
    0% { opacity: 1; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}